
@import "vendor/reset";
@import "vendor/function";
@import "vendor/mixin";
@import "vendor/extend";



html,body {
	margin: 0 auto;
	max-width: 640px;
	-webkit-text-size-adjust: 100%;
	font-family: "Microsoft JhengHei", "Apple LiGothic Medium", "sans-serif", "serif";
	font-size: 30px;
	*{
		box-sizing: border-box;
	}
	&.active{
		overflow: hidden;
	}
}

a{ text-decoration: none; display: block; }


a[href^=tel]{
	display: inline-block;
}

input{ -webkit-appearance: none; 
	&:focus{
		outline: none;
	}
}

figure{ line-height: 0; }
img{
	max-width: 100%;
	line-height: 0;
	width: 100%;
}

header{
	position: relative;
	line-height: rem(84px);
	background: #f18d00;
	.content{
		position: relative;
	}
	h1{
		text-indent: -9999px;
		background-image: inline('../images/logo.png');
		background-repeat: no-repeat;
		background-position: center center;
	}
	.hamburger{
		position: absolute;
		left: rem(30px);
		top: 50%;
		transform: translateY(-50%);
		width: rem(40px);
		height: rem(40px);
		background-image: inline('../images/hamburger.png');
		background-repeat: no-repeat;
		background-position: left center;
		background-size: cover;
		cursor: pointer;
		span{ display: none; }
	}
}

nav{
	position: absolute;
	top: rem(84px);
	left:0;
	z-index: 5;
	width: 100%;
	max-height: 0;
	transition: .3s;
	transform: scale(1,0);
	transform-origin: top center;
	overflow: hidden;
	&.is-active{
		
		max-height: rem(766px);
		transform: scale(1,1);
	}
	ul{
		border-top:1px solid #fff;
		>li{
			line-height: rem(84px);
			text-align: center;
			background: #ffcc33;
			border-bottom:1px solid #fff;
			a{
				font-size: rem(36px);
				font-weight: bold;
				color: #663300;
			}
		}
	}
	
}

// select{
// 	-webkit-appearance: none;
// }
.searchbar{
	background-image: inline('../images/bg_search.jpg');
	background-size: cover;
	padding: rem(12px) rem(30px);
	
	select,input[type='search']{
		padding: 0 rem(40px);
		width: 100%;
		border-radius: rem(27px);
		height: rem(54px);
		border: 0;
		background-color: rgba(#fff,0.5);
		margin-bottom: rem(8px);
		font-size: rem(28px);
		color: #535353;
		font-family: "Microsoft JhengHei";
		font-weight: bold;
		letter-spacing: rem(15px);
	}
	input[type='search']{
		letter-spacing: 0;
	}
}


input::-webkit-input-placeholder{
	text-align: center;
	color: #535353;
	letter-spacing: rem(30px);
}

footer{
	background-color: #f18d00;
	ul{
		display: flex;
		justify-content: space-between;
		text-align: center;
		>li{
			position: relative;
			line-height: rem(112px);
			width: 33.3%;
			&:not(:first-child):before{
				content: '';
				position: absolute;
				display: block;
				width: 1px;
				height: 100%;
				background: #fff;
			}
			a{
				color: #fff;
			}
		}
	}

}



@media (min-width: 320px), (max-width: 320px) { html, body { font-size: 13.5px; } }
@media (min-width: 360px) { html, body { font-size: 15px; } }
@media (min-width: 400px) { html, body { font-size: 16.5px; } }
@media (min-width: 440px) { html, body { font-size: 18px; } }
@media (min-width: 480px) { html, body { font-size: 19.5px; } }
@media (min-width: 520px) { html, body { font-size: 21px; } }
@media (min-width: 560px) { html, body { font-size: 22.5px; } }
@media (min-width: 600px) { html, body { font-size: 24px; } }
@media (min-width: 640px) { html, body { font-size: 30px; } }