//px to rem
@function rem($target, $context: 30px){
  @if $target == 0 { @return 0 }
  @return ($target / $context)+ 0rem;
}



// grid
@function span( $grid, $column, $gutter: 0 ){
  @return ( 100 / $grid ) * $column + 0% ;
}

@mixin span( $grid, $column, $gutter: 0 ,$nth: none){
  width: span( $grid, $column);
  padding: 0 rem($gutter) / 2;
  box-sizing: border-box;

  @if $nth == first{
    margin-left: rem(-$gutter) / 2;
  }@else if $nth == last{
    margin-right: rem(-$gutter) / 2;
  }@else if $nth == none{
    margin-left :auto;
    margin-right :auto;
  }
}