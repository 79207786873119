@import "layout";


section{
  position: relative;
  padding: rem(20px) rem(30px);
  width: 100%;
  background-size: cover;
  .usa{ 
    width: rem(164px);
    height: rem(164px);
    background-image: inline('../images/usa.png'); 
    background-size: cover;
  }
  .canada{ 
    width: rem(164px);
    height: rem(164px);
    background-image: inline('../images/canada.png'); 
    background-size: cover;
  }
  &.slider{
    padding: 0;
  }
}

.m-theme{
  border-radius: rem(27px);
  width: 100%;
  height: 100%;
  h2{
    border-radius: rem(27px);
    line-height: rem(54px);
    text-align: center;
    color: #fff;
    font-weight: bold;
    letter-spacing: rem(30px);
  }
  .bd{
    padding: rem(20px) rem(40px);
    .flag{
      display: flex;
      justify-content: center;
      >div{
        margin: rem(26px);
      }
    }
    > ul{
      display: flex;
      flex-wrap: wrap;
      border-top: 1px solid #fff;
      >li{
        position: relative;
        width: 50%;
        line-height: rem(94px);
        text-align: center;
        &:before{
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          border-bottom: 1px solid #fff;
          width: 100%;
          height: 100%;
          pointer-events: none;
        }
        &:nth-child(2n+1):before{
          border-right: 1px solid #fff;
        }
        a{
          color: #fff;
          font-weight: bold;
          white-space: nowrap;
        }
      }
    }
  }
}

.tour{
  background-image: url('../images/bg_tour.jpg');
  .m-theme{ background: rgba(#e94d5b,.6);
    h2{ background: rgba(#e94d5b,.7); }
  }
}
.abroad{
  background-image: url('../images/bg_abroad.jpg');
  .m-theme{ background: rgba(#f1ba7a,.6);
    h2{ background: rgba(#f1ba7a,.7); }
  }
}
.camp{
  background-image: url('../images/bg_camp.jpg');
  .m-theme{ background: rgba(#5d76b6,.6);
    h2{ background: rgba(#5d76b6,.7); }
  }
}

.student{
  // height: rem(606px);
  padding-bottom: rem(88px);
  background-image: url('../images/bg_student.jpg');
  .m-theme{ background: rgba(#8ccbc3,.6);
    h2{ background: rgba(#8ccbc3,.7); }
  }
}
.copyright{
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: rem(30px);
  text-align: center;
  font-size: rem(12px);
  font-weight: bold;
  color: #fff;
  letter-spacing: rem(2px);
}